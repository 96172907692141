<template>
  <span>
    <template v-if="p_perm.count !== ''">
      <b-badge variant="warning" style="margin-left: 10px" title="Sayısal limit"><i class="fa fa-calculator"></i> {{ p_perm.used === '' ? '0 / ' + p_perm.count : p_perm.used + ' / ' + p_perm.count }}</b-badge>
    </template>
    <template v-else>
      <b-badge variant="success" style="margin-left: 10px" title="Sayısal limit yok"><i class="fa fa-calculator" style="opacity: 0.65"></i></b-badge>
    </template>

    <template v-if="p_perm.date !== ''">
      <b-badge variant="warning" style="margin-left: 10px" title="Tarih limiti"><i class="fa fa-calendar"></i> {{ moment(p_perm.date).format('DD/MM/YYYY') }}</b-badge>
    </template>
    <template v-else>
      <b-badge variant="success" style="margin-left: 10px" title="Tarih limiti yok"><i class="fa fa-calendar" style="opacity: 0.65"></i></b-badge>
    </template>

    <template v-if="p_perm.license_id !== ''">
      <b-badge variant="warning" style="margin-left: 10px" title="Lisans bağlantılı"><i class="fa fa-certificate"></i> {{ p_perm.license_id }}</b-badge>
    </template>
    <template v-else>
      <b-badge variant="success" style="margin-left: 10px" title="Lisans bağlantısı yok"><i class="fa fa-certificate" style="opacity: 0.65"></i></b-badge>
    </template>

    <template>
      <b-badge variant="info" style="margin-left: 10px" :title="p_perm.source"><i class="fa fa-info"></i></b-badge>
    </template>
  </span>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';
import moment from 'moment';

export default {
  name: 'UserPermDetails',
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      patient: 'patient_selected'
    })
  },
  props: {
    p_perm: {
      type: Object,
      default: true
    },
    p_permValue: {
      type: String,
      default: false
    },
    p_permLabel: {
      type: String,
      default: false
    }
  },
  data () {
    return {};
  },
  created: function () {},
  beforeMount: function () {},
  methods: {},
  watch: {},
  components: {}
};

</script>
