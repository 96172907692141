<template>
  <div>
    <b-card class="card-accent-primary">
      <b-card-header header-bg-variant="secondary" class="p-1">
        Yetki Düzenleme Ekranı
        <b-button class="pull-right" size="sm" variant="success" @click="f_save()"><i class="fa fa-save"></i> Kaydet</b-button>
        <b-button class="pull-right" size="sm" variant="danger" @click="f_reset()"><i class="fa fa-ban"></i> Vazgeç</b-button>
      </b-card-header>
      <b-row>
        <b-col cols="12">
          <b-card class="card-accent-primary" no-body>
            <b-card-header header-bg-variant="secondary" class="p-1">
              Kullanıcı Yetkileri
            </b-card-header>
            <div style="max-height: 550px; overflow-x: hidden;">
              <table style="width: 100%">
                <template v-for="(current_perm_detail, current_perm_value, current_perm_ind ) in p_userPermissions">
                  <tr :key="'current_perm_' + current_perm_ind" :style="f_calculateRowStyle(current_perm_ind)">
                    <td>
                      <b-badge @click="f_showGivePermModal(current_perm_value)" v-if="current_perm_detail.source.length > 0" variant="warning" style="cursor: pointer;" title="Eklenebilir Yetki"><i class="fa fa-plus"></i></b-badge>
                    </td>
                    <td>
                      <b-badge @click="f_showGivePermModal(current_perm_value)" v-if="current_perm_detail.target.length > 0" variant="info" style="cursor: pointer;" title="Mevcut Yetki"><i class="fa fa-info"></i></b-badge>
                    </td>
                    <td>
                      {{ current_perm_detail.label }}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_showGivePermModal.perm && d_showGivePermModal.show" id="d_showGivePermModal.show" v-model="d_showGivePermModal.show" centered hide-header-close header-bg-variant="info" header-text-variant="dark" size="xl">
      <template slot="modal-title">
        Yetki Görüntüleme ve Düzenleme Ekranı --> {{ p_userPermissions[d_showGivePermModal.perm].label }}
      </template>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col cols="12">
              <b-card class="card-accent-primary" no-body>
                <b-card-header header-bg-variant="primary" class="p-1">
                  Kullanıcının mevcut yetkileri
                </b-card-header>
                <template v-if="p_userPermissions[d_showGivePermModal.perm].target.length > 0">
                  <template v-for="(target, target_ind) in p_userPermissions[d_showGivePermModal.perm].target">
                    <b-row :style="f_calculateRowStyle(target_ind)">
                      <b-col cols="12">
                        <user-perm-details :p_perm="target"></user-perm-details>
                      </b-col>
                    </b-row>
                  </template>
                </template>
                <template v-else>
                  <b-row>
                    <b-col cols="12">
                      Kullanıcının bu yetki türünden mevcut yetkisi bulunmamakta
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-card class="card-accent-primary" no-body>
            <b-card-header header-bg-variant="primary" class="p-1">
              Atama yapabileceğiniz yetkiler
            </b-card-header>
            <template v-if="p_userPermissions[d_showGivePermModal.perm].source.length > 0">
              <template v-for="(source, source_ind) in p_userPermissions[d_showGivePermModal.perm].source">
                <b-row :style="f_calculateRowStyle(source_ind)">
                  <b-col cols="12">
                    <b-button variant="success" size="sm" :disabled="f_checkIfAddPermDisable(source_ind)" @click="f_addPermToNewPerm(source, source_ind, d_showGivePermModal.perm, p_userPermissions[d_showGivePermModal.perm].label)"><i class="fa fa-arrow-right"></i></b-button>
                    <user-perm-details :p_perm="source"></user-perm-details>
                  </b-col>
                </b-row>
              </template>
            </template>
            <template v-else>
              <b-row>
                <b-col cols="12">
                  Bu yetki türünden atama yapma hakkına sahip değilsiniz
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <b-card class="card-accent-primary" no-body>
                <b-card-header header-bg-variant="primary" class="p-1">
                  Yeni eklenen yetkiler
                </b-card-header>
                <template v-for="(new_perm, new_perm_ind) in d_newAddedPermissions">
                  <template v-if="d_showGivePermModal.perm === new_perm.perm_value">
                    <b-row :style="f_calculateRowStyle(new_perm_ind)">
                      <b-col cols="12">
                        <b-row :key="'new_perm_' + new_perm_ind + '_2'" :style="f_calculateRowStyle(new_perm_ind)">
                          <b-col cols="1">
                            <b-button variant="danger" @click="f_removeFromNewPerm(new_perm.my_perm_ind, new_perm_ind)"><i class="fa fa-trash"></i></b-button>
                          </b-col>
                          <b-col cols="3">
                            Sayısal Limit:
                            <c-switch :disabled="new_perm.perm_count_limit !== ''" class="mx-1" color="success" v-model="new_perm.perm_count_operation" value="1" uncheckedValue="0" variant="pill" size="sm" />
                            <b-form-input v-if="new_perm.perm_count_operation.toString() === '1'" type="number" :min="0" :max="new_perm.perm_count_limit" v-model="new_perm.perm_count" required></b-form-input>
                          </b-col>
                          <b-col cols="3">
                            Tarih Limiti:
                            <c-switch :disabled="new_perm.perm_end_date_limit !== ''" class="mx-1" color="success" v-model="new_perm.perm_date_operation" value="1" uncheckedValue="0" variant="pill" size="sm" />
                            <datepicker v-if="new_perm.perm_date_operation.toString() === '1'" :config="{ enableTime: true, maxDate: new_perm.perm_end_date_limit}" class="form-control" v-model="new_perm.perm_end_date"></datepicker>
                          </b-col>
                          <b-col cols="3">
                            Yetki Verebilme:
                            <c-switch class="mx-1" color="success" v-model="new_perm.can_give_permission" value="1" uncheckedValue="0" variant="pill" size="sm" />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="d_showGivePermModal.show = false">Kapat</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';
import UserService from '@/services/users';
import Datepicker from "wisdom-vue-datepicker";
import vSelect from 'vue-select';
import { Switch as cSwitch } from '@coreui/vue'
import moment from 'moment';
import { ClsSearch } from '@/services/public/search';
import {
  default as UserPermDetails
} from '@/components/widgets/UserPermDetails';

export default {
  name: 'UserWdm248Management',
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      patient: 'patient_selected'
    })
  },
  props: {
    by_superuser: {
      type: Boolean,
      default: false
    },
    p_grantablePermissions: {
      type: Array,
      default: false
    },
    p_userPermissions: {
      type: Object,
      default: false
    },
    p_targetUser: {
      type: Object,
      default: false
    },
    mother_children: {
      type: Object,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      moment: moment,
      user: {},
      d_wdm22: {},
      d_showGivePermModal: { 'perm': '', 'show': false },
      d_newAddedPermissions: [],
      d_usedMyPerms: {},
      d_permissionsFiltered: [],
      d_searchText: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
  },
  beforeMount: function () {
    this.d_permissionsFiltered = JSON.parse(JSON.stringify(this.p_grantablePermissions));
  },
  methods: {
    f_checkIfAddPermDisable: function (my_perm_ind) {
      if (this.d_usedMyPerms[this.d_showGivePermModal.perm] && this.d_usedMyPerms[this.d_showGivePermModal.perm].indexOf(my_perm_ind.toString()) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    f_createPermText: function (perm, label) {
      let txt = '';

    },
    f_showGivePermModal: function (perm) {
      this.d_showGivePermModal.perm = perm;
      this.d_showGivePermModal.show = true;
    },
    f_reset: function () {
      this.d_newAddedPermissions = [];
      this.d_usedMyPerms = {};
      this.mother_children.d_showUserWdm248ManagementModal = false;
    },
    f_save: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı bilgisi kaydediliyor. Lütfen bekleyiniz' } });
      let data = {};
      data.target_user_id = this.p_targetUser.id;
      data.manager_user_id = this.d_wdm22.id;
      data.manager_user_name = this.d_wdm22.label;
      data.new_permission_list = this.d_newAddedPermissions;
      UserService.update_user_wdm248(data)
        .then(resp => {
          // this.d_grantablePermissions = resp.data.result;
          this.mother_children.d_showUserWdm248ManagementModal = false;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_calculateRowStyle: function (row_ind) {
      if (row_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    f_addPermToNewPerm: function (my_perm, my_perm_ind, perm_value, perm_label) {
      let push_data = {};

      if (my_perm.date !== '') {
        push_data.perm_end_date_limit = my_perm.date;
        push_data.perm_end_date = my_perm.date;
        push_data.perm_date_operation = "1";
      } else {
        push_data.perm_end_date_limit = '';
        push_data.perm_end_date = '';
        push_data.perm_date_operation = "0";
      }

      if (my_perm.count !== '') {
        if (my_perm.used !== '') {
          push_data.perm_count_limit = parseInt(my_perm.count) - parseInt(my_perm.used);
        } else {
          push_data.perm_count_limit = parseInt(my_perm.count);
        }
        push_data.perm_count = push_data.perm_count_limit;
        push_data.perm_count_operation = "1";
      } else {
        push_data.perm_count_limit = '';
        push_data.perm_count = '';
        push_data.perm_count_operation = "0";
      }

      push_data.perm_value = perm_value;
      push_data.perm_label = perm_label;
      push_data.perm_source = my_perm.source;
      push_data.my_perm_ind = my_perm_ind;
      push_data.license_id = my_perm.license_id;
      push_data.can_give_permission = "0";
      this.d_newAddedPermissions.push(push_data);
      if (!this.d_usedMyPerms[perm_value]) {
        this.d_usedMyPerms[perm_value] = [];
      }
      this.d_usedMyPerms[perm_value].push(my_perm_ind.toString());
    },
    f_removeFromNewPerm: function (my_perm_ind, new_perm_ind) {
      let my_perm_ind_ind = this.d_usedMyPerms[this.d_showGivePermModal.perm].indexOf(my_perm_ind);
      this.d_usedMyPerms[this.d_showGivePermModal.perm].splice(my_perm_ind_ind, 1)
      this.d_newAddedPermissions.splice(new_perm_ind, 1)
    },
    f_searchText: function () {
      this.d_permissionsFiltered = ClsSearch.list(this.d_searchText, this.p_grantablePermissions, this.d_permissionsFiltered, ['perm_label']);
    }
  },
  watch: {
    'd_searchText': function () {
      this.f_searchText();
    }
  },
  components: {
    cSwitch,
    UserPermDetails,
    Datepicker
  }
};

</script>

<style type="text/css">
.nav-item-selected {
  background: #d6f2b0;
}

.nav-item-not-selected {}

</style>

