<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <user-add-edit :by_superuser="true" :is_edit="is_edit" :username="username"></user-add-edit>
    </b-card>
  </div>
</template>

<script>
// import Vue from 'vue';
// import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from 'wisdom-config';
import {
  default as router
} from '@/router';
// import UserService from '@/services/users';
import {
  default as UserAddEdit
} from '@/components/widgets/UserAddEdit';

export default {
  name: 'UsersAddNew',
  computed: {},
  data () {
    return {
      is_edit: false,
      username: ''
    };
  },
  created: function () {
    if (router.currentRoute.name === 'hospital_all_user_settings_edit') {
      this.is_edit = true;
      this.username = router.currentRoute.query.username;
    }
  },
  methods: {},
  components: {
    UserAddEdit
  }
};

</script>

